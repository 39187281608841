body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "poppins", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html ::-webkit-scrollbar {
  display: none;
}

@media (max-width: 400px) {
  html {
    max-width: 100vw;
    margin: 0;
    padding: 0;
    background: white;
  }
}

.pagination-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
}
.pagination li {
  color: #0092e0;
  /* border: 1px solid #0092e0; */
  border:none !important;
  padding: 3px 10px 3px 10px;
}

.pagination li:hover {
  background: #0092e0;
  color: white;
  cursor: pointer;
}
.pagination .active {
  background: #0092e0;
  color: white;
}
.pagination .disabled {
  cursor: not-allowed;
  border:none !important;
}

.pagination .disabled a {
  cursor: not-allowed;
}

.pagination li a {
  text-decoration: none;
  outline: none;
}

.pagination li a span {
  font-weight: bold;
}

.add-new-btn {
  width: 200px;
  color: #0092e0;
  margin-right: 20px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-radius: 16px;
  opacity: 1;
  /* height: 30px; */
  outline: 0;
  border: 1px solid #0092e0;
  cursor: pointer;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.add-new-btn:hover {
  background: #0092e0 0% 0% no-repeat padding-box !important;
  color: white;
}
