.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select some files";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* .upload {
  background-image: url("../../asset/document-attach.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 70px;
  z-index: 20;
} */

.file_button_container,
.file_button_container input {
  height: 54px;
  width: 100%;
}

.file_button_container {
  background: transparent url("../../asset/attach-copy.svg") top left no-repeat;
  background-color: #0092e0;
  margin-bottom: -10px;
  border-bottom-left-radius: 2px;
  content: "upload";
  color: white;
}

.file_button_container input {
  opacity: 0;
}

/* .navbar {
  width: 100%;
  padding: 10px;
  position: fixed;
  top: 0;
  transition: top 0.6s;
}

.navbar--hidden {
  top: -50px;
} */
