body {
  font-family: "poppins";
  font-weight: normal;
  font-style: normal;
  background: #fafafa;
}

.form-surround::-webkit-scrollbar {
  display: none;
}
.form-surround {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.activation-text {
  font-size: 12px;
  color: green;
}
.forgot-password {
  display: flex;
  justify-content: center;
  width: 600px;
  background: white;
  margin: auto;
  margin-top: 80px;
  border-radius: 9px;
}

.forgot-password-content {
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  width: 350px;
  text-align: center;
  margin: 0 auto;
}
.reset-create {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.has-account {
  text-align: center;
  font-size: 16px;
  font-weight: bold
}
.forgot-pass-img {
  width: 200px;
  height: 40px;
}

/* .forgot-pass-input {
  outline: 0;
  height: 40px;
  border-radius: 15px;
  width: 350px;
} */
.email-text {
  font-size: 14px;
}
.reset-create p {
  font-size: 16px;
  color: #707070;
  font-weight: 400;
}
.reset-create .cntct {
  color: #0092e0;
  text-decoration: none;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.main-sec{
  display: flex;
  height: calc(100vh - 60px);
  margin-top: 60px
}
.top-nav{
  position: fixed;
  width: 100%;
  height: 40px;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(234, 234, 234);
  opacity: 1;
  padding: 10px;
  padding-left: 30px;
}

.top-nav img{
  width: 150px;
  height: 30px;
}

.form-control {
  width: 180px !important;
  border: 2px solid #e2e2e2 !important;
  height: 34px !important;
}
.error {
  display: flex;
  justify-content: center;
  color: red;
  font-size: 10px;
}
.exist{
  font-size: 14px !important;
}
.image-section {
  background-image: url("../../asset/ManHiRes1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 60%;
}

.country {
  height: 34px;
  border: 2px solid #e2e2e2;
  outline: none;
  border-radius: 9px;
}

.react-tel-input .form-control{
  width: 100% !important
}

#pass {
  margin-bottom: 10px;
}
.activated-section {
  background: white;
  width: 30%;
}
.activated-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 250px;
  overflow: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000014;
  border-radius: 15px;
  top: 60px;
  left: 100px;
}
.activated-wrapper button .login-redirect {
  text-decoration: none;
  color:white
}
.activated-wrapper button {
  width: 70%;
  color: white;
  background: #0092e0 0% 0% no-repeat padding-box !important;
  border-radius: 13px;
  opacity: 1;
  outline: 0;
  border: 1px solid #0092e0;
  cursor: pointer;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}
.activated-wrapper h3 {
  color: #0092e0;
}
.activated-wrapper p {
  text-align: center;
  letter-spacing: 0.44px;
  color: #173049;
  margin: 10px;
  font-family: "poppins"
}
.activated-wrapper span {
  font-weight: bolder !important;
}

.form-section {
  background: white;
  /* min-height: 100vh; */
  width: 40%;
  padding-left: 30px;
  /* position: relative; */
}
.form-wrapper {
  width: 33vw;
  min-height: 500px;
  overflow: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 30px #00000014;
  border-radius: 15px;
  margin-top: 20px
}
.login-wrapper {
  min-height: auto !important;
}

.logininput, .passwordinput {
  height: 40px !important;
  font-size:14px
}

.form-surround {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: scroll;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 7px;
  box-shadow: 0px 0px 10px #00000029;
}
.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: -10px;
}
.login-container {
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 7px;
  box-shadow: 0px 0px 10px #00000029;
}
.great {
  color: orangered;
  margin: 0 !important;
  font-family: "poppins";
  font-size: 20px;
}
label {
  font-size: 14px;
  font-family: "poppins";
}

.input {
  border: 2px solid #e2e2e2;
  outline: none;
  border-radius: 9px;
  height: 28px;
  font-family: "poppins" !important;
}
.firstname-input {
  width: 48%;
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
}

.lastname-input {
  width: 48%;
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
}

.name-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}
.email-input {
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
  width: 100%;
  margin-bottom: 15px;
}

label {
  margin-bottom: 5px;
}
.select-input {
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
  width: 45%;
  margin-bottom: 10px;
}
.country-phone {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.country-input {
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
  width: 48%;
  /* margin-bottom: 10px; */
}
.country-input select {
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
}

.phone {
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
  width: 48%;
  margin-bottom: 10px;
}
.phone input {
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
}
.password-input {
  display: flex;
  flex-direction: column;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
  width: 100%;
}

.custom-icon {
  position: absolute;
  left: auto;
  right: 20px;
  top: 36px;
}

.visible-icon {
  position: absolute;
  left: auto;
  right: 20px;
  top: 42px;
  cursor: pointer;
}

.newpassword-input {
  border: 2px solid #e2e2e2;
  outline: none;
  border-radius: 9px;
  height: 30px;
  width: 100%;
}

.register-button {
  width: 100%;
  background: #0092e0 0% 0% no-repeat padding-box;
  border-radius: 9px;
  outline: none;
  height: 38px;
  border: 1px solid #0092e0;
  margin-top: 20px;
  color: #ffffff;
  cursor: pointer;
  font-family: "poppins";
  letter-spacing: 0.24px;
  opacity: 1;
}

.resetpassword-button {
  width: 100% !important;
  background: #0092e0 0% 0% no-repeat padding-box;
  border-radius: 9px;
  outline: none;
  height: 38px;
  border: 2px solid #0092e0;
  margin-top: 20px;
  color: white;
}

.terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
  margin-top: 10px;
}

.terms span{
  font-family: "poppins";
}
/* .accept .agree {
  font-size: 12px;
} */

#accountType {
  height: 34px;
}

.facebook {
  width: 48% !important;
  height: 32px !important;
  background: #0092e0 0% 0% no-repeat padding-box !important;
  border-radius: 9px !important;
  outline: none !important;
  border: 1px solid #0092e0 !important;
  color: white !important;
}
.intro-text {
  font-size: 12px;
  /* width: 100%; */
  margin-bottom: 30px;
}

.signup-with {
  font-size: 14px;
  font-family: "poppins";
  color: #707070;
}
.paragraph {
  font-size: 14px;
  margin-top: 15px;
  font-family: "poppins";
  letter-spacing: 0.24px;
  color: #707070;
  opacity: 1;
}

.google {
  width: 41%;
  height: 36px;
  background: #ff2600 0% 0% no-repeat padding-box !important;
  border-radius: 9px !important;
  outline: none !important;
  border: 1px solid #ff2600 !important;
  color: white;
}
.fa-google {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
.google span {
  color: white;
}
.terms .accept{
display: flex;
cursor: pointer

}
.terms .accept span {
  font-size: 14px;
  font-family: "poppins";
}
.fot-pass {
  text-decoration: none;
  color: #0092e0;
}
.terms .link {
  font-size: 12px;
  font-family: "poppins";
  color: #707070;
}
.terms .accept .agree-box{
  width: 16px;
  height: 16px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 10px;
}

.terms a {
  font-size: 14px;
  text-decoration: none;
  font-family: "poppins";
  color: #707070;
}

.text-header {
  display: flex;
  justify-content: center;
  margin-top: -15px;
  font-family: "poppins";
  letter-spacing: 0px;
  color: #ff2600;
  font-size: 24px;
  opacity: 1;
  /* margin-bottom: 5px; */
}

@media screen and (max-width: 800px) {
  .text-header {
    font-size: 18px;
    margin-bottom: 25px;
  }

  .image-section {
    display: none;
  }

  .login-image-section {
    display: none;
  }
  .container {
    width: 100%;
    margin: 0 auto;
  }

  #accountType {
    height: 40px;
  }
  .signup-with {
    font-size: 16px;
  }
  .form-section {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    height: 850px;
  }
  label {
    font-size: 16px;
  }

  .input {
    font-size: 18px;
    height: 38px;
  }
  .intro-text {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
  }
  .fields {
    margin-bottom: 30px;
  }

  .register-button {
    height: 55px;
    margin-top: -5px;
    font-size: 18px;
  }
  .country {
    height: 42px;
    font-size: 18px;
  }

  .custom-icon {
    top: 34px;
  }
  .paragraph {
    font-size: 18px;
  }

  .terms .accept span {
    font-size: 18px;
  }

  .terms a {
    font-size: 18px;
    text-decoration: none;
  }

  .check {
    font-size: 18px;
  }
  .google {
    height: 45px;
    font-size: 18px;
  }
  .facebook {
    height: 45px;
    font-size: 18px;
  }
}
@media screen and (max-width: 500px) {
  .form-control {
    width: 100% !important;
    height: 40px !important;
  }
  .custom-icon {
    top: 45px;
  }
  .visible-icon {
    position: absolute;
    left: auto;
    right: 20px;
    top: 42px;
  }
  .loginbtn {
    height: 42px !important;
  }
  .form-section {
    width: 100%;
    margin-top: 0px;
    max-height: 100vh;
  }
  .login-wrapper {
    width: 90% !important;
    left: 20px !important;
  }
  .form-wrapper {
    width: 90% !important;
    left: 20px !important;
    top: 15px !important;
  }
  .forgot-password {
    max-width: 350px !important;
  }
  .reset-create {
    width: 85%;
  }
  #login-section {
    height: 400px;
  }

  .name-section {
    display: flex;
    flex-direction: column;
  }
  .country-phone {
    display: flex;
    flex-direction: column;
  }
  .firstname-input,
  .lastname-input,
  .input {
    width: 100%;
  }
  .firstname-input {
    margin-bottom: 30px;
  }

  .phone {
    width: 100%;
  }

  .country-input {
    margin-bottom: 30px;
  }
  .acctype {
    margin-bottom: 0px !important;
  }
  .country-input,
  .select-input {
    width: 100%;
  }
  .fields {
    margin-bottom: 30px;
  }
  .register-button {
    height: 35px;
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
  }
  .terms {
    display: flex;
    align-items: center;
  }
  .terms .accept span {
    font-size: 15px;
    width: 100%;
  }

  .kep-login-facebook {
    height: 42px !important;
    padding: 0px;
    padding-top: 10px !important;
  }
  .terms a {
    font-size: 14px;
    color: #0092e0;
  }
  .paragraph {
    font-size: 15px;
  }
  .intro-text {
    font-size: 14px;
  }
  .google {
    height: 42px;
    width: 120px;
  }
  .activated-section {
    display: none;
  }
}

@media screen and (max-width: 800px) and (min-width:700px) {
  .register-button {
    margin-top:20px
}
.fields{
  margin-bottom:15px
}
}